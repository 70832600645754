/*
* Admin Layout (fruits)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:fruits
* Copyright 2022 Pixelwibes
*/
.fc {
  * {
    color: var(--primary-color);
  }
  .fc-col-header-cell-cushion {
    color: var(--primary-color);
  }
  .fc-h-event .fc-event-title {
    color: var(--white-color);
  }
  .fc-h-event {
    background-color: var(--primary-color);
    border-color: var(--border-color);
  }
  .fc-daygrid-event-dot {
    border-color: var(--secondary-color);
  }
  .fc-button-primary {
    background-color: var(--secondary-color);
    color: var(--white-color);
    border-color: var(--secondary-color);
    .fc-icon {
      color: var(--white-color);
    }
  }
  .fc-daygrid-event {
    @media only screen and (max-width: 1280px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 8px;
    }
  }
  .fc-daygrid-event {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.fc-theme-standard {
  .fc-scrollgrid,
  td,
  th {
    border-color: var(--border-color);
  }
}
