/*
* Admin Layout (fruits
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:fruits
* Copyright 2022 Pixelwibes
*/
//Fonts Family
$font-family: 'IBM Plex Sans', sans-serif;
$font-opensans: 'Open Sans', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-montserrat: 'Montserrat', sans-serif;
$font-Plex: 'IBM Plex Sans', sans-serif;

// Bootstrap colors
$color-success: #4dca88;
$color-danger: #fc5a69;
$color-warning: #ffba33;
$color-info: #4fb8c9;
$color-secondary: #6c757d;

// Fonts
$font-size: 14px;
$text-color: var(--font-color);

$tradewind: #6aab9c !default;
$monalisa: #ff7b89 !default;
$indigos: #484c7f !default;
$purples: #484c7f !default;
$oranges: #f8b500 !default;
$yellow: #ffba33 !default;
$greens: #7eb93f !default;
$blues: #7258db !default;
$blush: #ed5782 !default;
$cyans: #00bdaa !default;
$reds: #ff1843 !default;
$info: #4fb8c9 !default;
$white: #ffffff !default;
$dark: #191818 !default;

// scss-docs-start colors-map
$c_colors: (
  'tradewind': #6aab9c,
  'monalisa': #ff7b89,
  'blue': #7258db,
  'blush': #ed5782,
  'indigo': #484c7f,
  'purple': #484c7f,
  'red': #ff1843,
  'orange': #f8b500,
  'yellow': #ffba33,
  'green': #7eb93f,
  'cyan': #00bdaa,
) !default;

$sidebar-width: 250px;
$rightbar-width: 280px;
$sidebar-icon: 50px;

// Breakpoints
$break-xxsmall: 390px;
$break-xsmall: 640px;
$break-small: 768px;
$break-medium: 992px;
$break-1024: 1024px;
$break-large: 1200px;
$break-1280: 1280px;
$break-xlarge: 1440px;
