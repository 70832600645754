/*
* Admin Layout (fruits)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:fruits
* Copyright 2022 Pixelwibes
*/

// bootstrap class overwrite css
.border,
.border-top,
.border-right,
.border-bottom,
.border-left,
.dropdown-divider {
  border-color: var(--border-color);
}

.border {
  &.border-primary {
    border-color: var(--primary-color) !important;
  }
  &.border-secondary {
    border-color: var(--secondary-color) !important;
  }
  &.border-success {
    border-color: $color-success !important;
  }
  &.border-danger {
    border-color: $color-danger !important;
  }
  &.border-warning {
    border-color: $color-warning !important;
  }
  &.border-info {
    border-color: $color-info !important;
  }
  &.border-dark {
    border-color: var(--color-900) !important;
  }
  &.border-white {
    border-color: var(--color-fff) !important;
  }
}

.container-xxl {
  max-width: 1560px;
}
.font-weight-bold {
  font-weight: bold;
}
.table > :not(caption) > * > * {
  padding: 0.8rem 0.8rem;
}
.zindex-fixed {
  z-index: $zindex-fixed;
}
.zindex-popover {
  z-index: $zindex-popover;
}
.zindex-modal {
  z-index: $zindex-modal;
}
.small-xs {
  font-size: 13px !important;
}

// .form-control
.ant-form-item {
  .ant-form-item-margin-offset {
    display: none;
  }
  .ant-form-item-explain {
    color: $color-danger;
    font-size: 13px;
    padding: 2px 0 0 2px;
  }
  .ant-input-number {
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input-wrap {
      input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        border: 1px solid var(--border-color);
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
    .invalid-feedback
  )
  .ant-input-number-input-wrap
  input {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating)
  .ant-input-number-input-wrap
  input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group .ant-input-number {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.auth-page {
  .ant-form-item {
    width: 340px;
    @media (max-width: 375px) {
      width: 280px;
    }
  }
}
.bootstrap-tagsinput {
  width: 100%;
}
.form-label {
  font-weight: 600;
}

.form-control,
.ant-form-item .ant-input-number .ant-input-number-input-wrap input,
.form-select {
  border-color: var(--border-color);
  background-color: var(--card-color);
  color: var(--text-color);
  font-size: 16px;
  outline: none;
  &::placeholder {
    color: var(--color-400);
  }
  &:focus,
  &:focus-visible,
  &:focus-within {
    box-shadow: 0 0 10px rgba($dark, 0.15);
    background-color: var(--card-color);
    border-color: var(--primary-color);
    color: var(--text-color);
  }
  &:disabled {
    background-color: var(--body-color);
  }

  &.form-control-lg {
    min-height: calc(1.5em + 1.8rem + 2px);
  }
}

.input-group-text {
  border-color: var(--border-color);
  background-color: var(--color-200);
  color: var(--text-color);
}

.form-control-lg {
  @media only screen and (max-width: $break-small) {
    min-height: calc(1.5em + 1rem + 2px) !important;
  }
}

.badge-info {
  background: var(--primary-color);
}

// form Switches css
.form-switch {
  .form-check-input {
    cursor: pointer;
    &:checked {
      background-color: var(--primary-color);
      border-color: var(--border-color);
    }
  }
}
.btn-link {
  color: var(--text-color);
  &:hover {
    color: var(--secondary-color);
  }
}
.bg-white {
  color: var(--text-color);
}
//lineheight
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
  line-height: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
  line-height: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
  line-height: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
  line-height: calc(1.275rem + 0.3vw) !important;
}
// bottom
.btn {
  font-size: $font-size;
  color: var(--text-color);
  &:hover {
    color: var(--text-color);
  }
  &.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white-color);
    &.active {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }

  &.btn-light-primary {
    background-color: rgba(var(--primary-rgb), 0.15);
    color: var(--primary-color);
    border-color: transparent;

    &:hover {
      background-color: var(--primary-color);
      color: $white;
    }
  }

  &.btn-light-secondary {
    background-color: rgba($color-secondary, 0.15);
    color: $color-secondary;
    border-color: transparent;

    &:hover {
      background-color: $color-secondary;
      color: $white;
    }
  }

  &.btn-light-success {
    background-color: rgba($color-success, 0.15);
    color: $color-success;
    border-color: transparent;

    &:hover {
      background-color: $color-success;
      color: $white;
    }
  }

  &.btn-light-danger {
    background-color: rgba($color-danger, 0.15);
    color: $color-danger;
    border-color: transparent;

    &:hover {
      background-color: $color-danger;
      color: $white;
    }
  }

  &.btn-light-warning {
    background-color: rgba($color-warning, 0.15);
    color: $color-warning;
    border-color: transparent;

    &:hover {
      background-color: $color-warning;
      color: $white;
    }
  }

  &.btn-light-info {
    background-color: rgba($color-info, 0.15);
    color: $color-info;
    border-color: transparent;

    &:hover {
      background-color: $color-info;
      color: $white;
    }
  }

  &.btn-outline-primary {
    color: var(--primary-color);
    border-color: var(--primary-color);
    &:hover {
      color: $white;
      background-color: var(--primary-color);
    }
    &.active {
      background-color: var(--primary-color);
      color: var(--white-color);
    }
  }
  &.btn-outline-secondary {
    border-color: var(--border-color);
    &:hover {
      color: $white;
      background-color: var(--secondary-color);
      i {
        color: $white !important;
      }
    }
  }
  &.btn-outline-success {
    color: $color-success;
    border-color: $color-success;
    &:hover {
      background-color: $color-success;
      color: $white;
    }
  }
  &.btn-outline-danger {
    color: $color-danger;
    border-color: $color-danger;
    &:hover {
      background-color: $color-danger;
      color: $white;
    }
  }
  &.btn-outline-info {
    color: $color-info;
    border-color: $color-info;
    &:hover {
      background-color: $color-info;
      color: $white;
    }
  }
  &.btn-dark {
    color: var(--white-color);
  }
  &.btn-secondary {
    color: var(--white-color);
  }
  &.btn-lg {
    padding: 0.7rem 1rem;
  }
}
.btn-close {
  color: var(--text-color);
}
// avatar
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;

  &.xs {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
  &.sm {
    width: 22px !important;
    min-width: 22px !important;
    height: 22px !important;
    i {
      font-size: 12px;
      line-height: 12px;
      vertical-align: text-top;
    }
  }
  &.lg {
    width: 56px;
    min-width: 56px;
    height: 56px;
  }
  &.xl {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }
  &.xxl {
    width: 120px;
    min-width: 120px;
    height: 120px;
  }
  &.no-thumbnail {
    background-color: rgba(var(--primary-rgb), 0.15);
    color: var(--primary-color);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.min-width-55 {
  min-width: 55px;
}
// dropdown menu
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: rgba($dark, 0.1);
}
.dropdown-item {
  color: var(--text-color);
  &:hover {
    color: var(--secondary-color);
  }
}
.dropdown-menu {
  font-size: $font-size + 1px;
  color: var(--text-color);
  background-color: var(--body-color);
  li {
    margin: 5px 0px;
  }
  .dropdown-item {
    color: var(--text-color);
    &.active {
      // color: var(--secondary-color);
      background-color: var(--primary-color);
    }
    &:active,
    &:focus {
      background-color: var(--primary-color);
      // color: var(--secondary-color);
    }
    &:hover {
      color: var(--secondary-color);
    }
  }
}

// custom modal popup css
.modal {
  right: 0;
  left: auto;
  z-index: 99999;

  &.fade {
    .modal-dialog-vertical {
      transform: translateX(-100%);
    }
  }
  &.show {
    .modal-dialog-vertical {
      transform: translateX(0);
    }
  }
  .modal-dialog-vertical {
    margin: 0;
    height: 100%;
    .modal-content {
      // height: 100%;
    }
  }
  .modal-header,
  .modal-footer {
    border-color: var(--border-color);
  }
  .modal-content {
    background-color: var(--body-color);
  }
}

.modal {
  &.right {
    z-index: 99999;
    margin-top: 25px;
    margin-bottom: 25px;
    @media only screen and (max-width: 1279px) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .modal-dialog {
      height: calc(100vh - 50px) !important;
      position: fixed;
      margin: auto;
      width: 320px;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      @media only screen and (max-width: 1279px) {
        height: 100vh !important;
      }
      @media only screen and (max-width: 767px) {
        height: 100vh !important;
      }
    }
    .modal-content {
      // height: 100%;
      border-radius: 1.1rem !important;
      @media only screen and (max-width: 1279px) {
        border-radius: 0px !important;
      }
      .setting-theme,
      .dynamic-block,
      .setting-font,
      .sidebar-gradient {
        border-bottom: 1px solid #e2e2e2;
      }
      .setting-font,
      .setting-mode,
      .dynamic-block {
        .list-group .list-group-item {
          background-color: transparent;
          border-color: transparent;
          padding: 0px !important;
          margin-bottom: 5px;
        }
      }
      .font_setting {
        .form-check-input:checked {
          background-color: var(--secondary-color);
          border-color: var(--secondary-color);
          background-image: inherit;
        }
      }
      .setting-mode {
        .form-check-input:checked {
          background-color: var(--secondary-color);
          border-color: var(--secondary-color);
        }
      }
      .dynamic-block {
        .list-group-item {
          .avatar {
            &.xs {
              width: 25px;
              height: 25px;
              min-width: 25px;
              border-radius: 5px !important;
            }
          }
        }
      }
      .modal-footer {
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
    }
    .modal-body {
      @include overflow(scroll);
      overflow-y: auto;
    }
    &.fade {
      .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
      }
      &.show {
        .modal-dialog {
          right: 10px;
          @media only screen and (max-width: 1279px) {
            right: 0px;
          }
        }
      }
    }
  }
}

// breadcrumb
.breadcrumb {
  background-color: var(--color-200);
}

// navbar
.navbar-light {
  .navbar-nav {
    .nav-link {
      color: var(--color-500);
      &:hover,
      &:focus {
        color: var(--primary-color);
      }
    }
  }
}

// custom tab ui
.nav-tabs {
  border-color: var(--border-color);
  &.tab-custom {
    border: 1px solid rgba($white, 0.2);
    .nav-link {
      color: $white;
      opacity: 0.7;
      &.active {
        opacity: 1;
        color: var(--primary-color);
      }
    }
  }
  &.tab-card {
    .nav-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      background-color: transparent;
      border-bottom: 3px solid transparent;
      color: var(--color-500);

      &.active {
        border-color: var(--primary-color);
      }
    }
  }
  &.tab-body-header {
    border: 1px solid Var(--primary-color);
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    .nav-item {
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      .nav-link {
        border: 0;
        margin: 2px;
        border-radius: 3px;
        color: var(--color-500);
        padding: 0.2rem 0.8rem;
        &:hover {
          color: var(--primary-color);
        }

        &.active {
          background-color: var(--primary-color);
          color: $white;
        }

        &.buy {
          &.active {
            background-color: var(--buy-color);
            color: $white;
          }
        }

        &.sell {
          &.active {
            background-color: var(--sell-color);
            color: $white;
          }
        }
      }
    }
  }
}

// nav pill
.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    background-color: var(--primary-color);
  }
  .nav-link {
    color: var(--text-color);
  }
}

//border
.border-dark {
  border-color: var(--text-color) !important;
}
.border-start {
  border-color: var(--border-color) !important;
}

// list-group design
.list-group-custom,
.list-group {
  .list-group-item {
    background-color: var(--card-color);
    border-color: var(--border-color);
    color: var(--text-color);
    &.active {
      background-color: var(--primary-color);
    }
  }
}

// .progress bar design
.progress {
  background-color: var(--color-100);
  &.plan-active {
    background-color: var(--color-300);
  }
}

// table
.table {
  border-color: var(--border-color);
  tr {
    th {
      //border-color: var(--primary-color);
      color: var(--color-800);
      text-transform: uppercase;
      font-size: 12px;
    }
    td {
      border-color: var(--border-color);
      color: var(--color-500);
      padding: 0.8rem 0.6rem;
    }
    &:hover {
      td {
        color: var(--color-900);
      }
    }
  }
  &.border-primary {
    tr th,
    tr td {
      border-color: var(--primary-color);
    }
  }
  &.table-sm {
    td {
      padding: 0.5rem 0.6rem;
    }
  }
  &.custom-table {
    border-collapse: separate !important;
    border-spacing: 0 5px !important;

    thead {
      tr {
        th {
          border: 0;
        }
      }
    }
    tbody {
      tr {
        background: var(--card-color);
        &.cancle-row {
          td {
            //background: rgba($color-danger, .05);
          }
        }
      }
    }
    tr {
      td,
      th {
        vertical-align: middle;
        white-space: nowrap;
        padding-left: 1rem;
        padding-right: 1rem;
        border-right: 0;
        border-left: 1px solid var(--border-color);

        &:first-child {
          border-radius: 0.25rem 0 0 0.25rem;
          border-left: 0;
        }
        &:last-child {
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }
  }
  &.custom-table-2 {
    border-collapse: separate !important;
    border-spacing: 0 1px !important;

    thead {
      tr {
        th {
          border: 0;
        }
      }
    }
    tbody {
      tr {
        background: var(--card-color);
        &.cancle-row {
          td {
            background: rgba($color-danger, 0.05);
          }
        }
        &.active-row {
          td {
            background: rgba($color-info, 0.05);
          }
        }
      }
    }
    tr {
      td,
      th {
        vertical-align: middle;
        white-space: nowrap;
        padding-left: 1rem;
        padding-right: 1rem;
        border-right: 0;

        &:first-child {
          border-radius: 0.25rem 0 0 0.25rem;
          border-left: 0;
        }
        &:last-child {
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }
  }
  &.accordion {
    border-collapse: separate !important;
    border-spacing: 0 1px !important;

    thead {
      tr {
        th {
          border: 0;
        }
      }
    }
    tbody {
      tr {
        background: var(--card-color);
        &.cancle-row {
          td {
            background: rgba($color-danger, 0.05);
          }
        }
        &.active-row {
          td {
            background: rgba($color-info, 0.05);
          }
        }
      }
    }
    tr {
      td,
      th {
        padding-left: 1rem;
        padding-right: 1rem;
        border-right: 0;

        &:first-child {
          border-radius: 0.25rem 0 0 0.25rem;
          border-left: 0;
        }
        &:last-child {
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }
  }
}

// pagination
.page-item {
  &.disabled {
    .page-link {
      background-color: var(--border-color);
      border-color: var(--border-color);
    }
  }
  .page-link {
    background-color: var(--card-color);
    border-color: var(--border-color);
    color: var(--primary-color);
  }
  &.active {
    .page-link {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--white-color);
    }
  }
}

//Side navbar
.side-navbar {
  li {
    margin-bottom: 5px;
    a {
      color: var(--text-color);
    }
  }
}

//button
.btn-outline-secondary.btn-block {
  color: #fff !important;
}

//navbar
.navbar-toggler:focus {
  box-shadow: none;
}

//Date & Time
input[type="date"],
input[type="time"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-width: 95%;
}

//tageinput
.bootstrap-tagsinput,
.dropify-wrapper {
  border: 1px solid var(--border-color);
  background-color: var(--card-color);
}

@media (min-width: 1200px) {
  .col-xl-3-5 {
    flex: 0 0 auto;
    width: 30%;
  }
  .col-xl-7-5 {
    flex: 0 0 auto;
    width: 70%;
  }
}
