.kline-chart {
    width: 100%;
    height: 465px;
    .klinecharts-pro-period-bar {
        justify-content: space-between;
        .period+.tools {
            border: none;
            margin-left: 0;
        }
        .symbol {
            border: none;
            font-size: 14px;
            padding: 0 10px;
        }
        .period {
            font-size: 13px;
        }
        .tools {
            border: none;
            padding: 0 10px;
            > span:last-child {
                display: none;
            }
        }
        .menu-container {
            width: 32px;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
    .klinecharts-pro-content {
        .klinecharts-pro-drawing-bar {
            width: 32px;
            .item {
                >span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon-overlay {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .klinecharts-pro-widget {
            width: calc(100% - 32px);
        }
    }
}
